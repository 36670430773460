.container {
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-row: 2;
}

button {
  padding: 0.5rem 1rem;
  border: 2px solid var(--darkGreen);
  background-color: var(--black);
  border-radius: 0.2rem;
  color: var(--lightGreen);
  font-size: 1.25rem;
  cursor: pointer;
  font-family: MadHacker;
  color: var(--lightGreen);
}
.btnIcon {
  position: absolute;
  margin: 0.75rem;
  font-size: 1.25rem;
  color: var(--darkGreen);
}
button:hover {
  background-color: var(--lightGreen);
  color: var(--black);
}
button:hover span {
  color: var(--black);
}

form {
  display: flex;
  gap: 3rem;
  flex-direction: column;
  width: min(550px, 90%);
}
form > div {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.row {
  position: relative;
}
.row input {
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 0.2rem;
  padding-left: 2.5rem;
  width: min(550px, 90%);
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 2px solid var(--darkGreen);
  color: var(--white);
  font-size: 1.5rem;
}
.row label {
  position: absolute;
  top: 0;
  margin: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: all 0.4s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--darkGreen);
}
.row input:focus {
  border-bottom-color: var(--lightGreen);
}
.row label:focus {
  color: var(--lightGreen);
}
.row input:focus + label {
  transform: translateY(-30px);
  font-size: 1rem;
  color: var(--lightGreen);
}
.row .valid {
  transform: translateY(-30px);
  font-size: 1rem;
  color: var(--lightGreen);
}

.row label > span {
  display: flex;
}

.iconCheck,
.iconCross {
  position: absolute;
  margin: 0.6rem;
  font-size: 1.5rem;
}
.iconCross {
  color: var(--red);
}
.iconCheck {
  color: var(--lightGreen);
}

@media screen and (max-width: 768px) {
  form {
    padding: 2rem;
  }
}
