.container {
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 2;
}
.timerHeader {
  font-size: 10rem;
  color: var(--lightGreen);
  margin: 0;
}

@media screen and (max-width: 768px) {
  .timerHeader {
    font-size: 5rem;
  }
}
