.shapes {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}
.shapesContainer {
  display: flex;
  gap: 0.2rem;
}

