.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 100vh;
}
.container h1 {
  margin: 0;
  color: var(--lightGreen);
  font-size: 3rem;
  text-transform: uppercase;
}
.container h2 {
  margin: 0;
  color: var(--white);
}
.container h3 {
  color: var(--lightGreen);
  font-size: 1.5rem;
}
.container span {
  color: var(--lightGreen);
}
