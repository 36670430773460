.wrapper {
  background-color: var(--lightGreen);
  animation: zoomIn 1s;
}
.gameContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0.2rem;
  min-height: 100vh;
}
@media screen and (max-width: 768px) {
  .gameContainer {
    display: flex;
    flex-direction: column-reverse;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
