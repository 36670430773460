.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100vh;
}
h1,
h2,
h3 {
  margin: 0;
  color: var(--lightGreen);
}
