.textBox {
  width: min(800px, 90%);
}
h2 {
  color: var(--white);
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}
p {
  margin: 0;
  font-size: 1.5rem;
  color: var(--darkGreen);
}
span {
  color: var(--lightGreen);
}
