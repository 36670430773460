@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap");
@font-face{
  font-family: "MadHacker";
  src: local(MadHacker),
  url("./fonts/MadHacker.ttf") format("truetype");
  font-weight:normal;
}
:root {
  --white: #ffffff;
  --lightGreen: #72e5be;
  --darkGreen: #17825e;
  --black: #141E27;
  --red: #f15d58;
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: Syne, "MadHacker", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
}