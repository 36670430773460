.container {
  grid-row: 1;
  grid-column: span 2;
  background-color: var(--black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
  margin: 0;
  width: 100%;
}
.clueHeader {
  color: var(--white);
}
.clues {
  color: var(--lightGreen);
}
.card {
  width: 320px;
  height: 370px;
  perspective: 500px;
  cursor: pointer;
}
.inner {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.flipped {
  transform: rotateY(180deg);
  transition: transform 1s;
}
.front,
.back {
  position: absolute;
  width: 300px;
  height: 300px;
  background-color: var(--black);
  border-radius: 5px;
  backface-visibility: hidden;
  animation: border 5s infinite;
  font-size: 1.2rem;
  letter-spacing: 2px;
  white-space: pre-line;
  padding: 8px;
}
.front {
  color: white;
  transition: 0.5s ease-in-out;
  text-align: center;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}
.front:hover {
  color: var(--lightGreen);
  transition: 0.5s ease-in-out;
  font-size: 2.5rem;
}
.back {
  color: white;
  border: 2px solid var(--lightGreen);
  transform: rotateY(180deg);
  transition: transform 0.5s;
  animation: unset;
}
.header {
  color: var(--white);
  font-size: 2rem;
  font-weight: bold;
  grid-row: 2;
}

@keyframes fontSize {
  0% {
    fontsize: 2rem;
  }
  100% {
    fontsize: 3rem;
  }
}
@keyframes border {
  0% {
    border: 2px var(--lightGreen) solid;
  }
  25% {
    border: 2px var(--darkGreen) solid;
  }
  50% {
    border: 2px var(--lightGreen) solid;
  }
  75% {
    border: 2px var(--darkGreen) solid;
  }
  100% {
    border: 2px var(--lightGreen) solid;
  }
}
