:root {
  --white: #ffffff;
  --lightGreen: #72e5be;
  --black: #141e27;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 100vh;
  background-color: var(--black);
  gap: 5rem;
}

.line {
  height: 0.1rem;
  width: 100%;
  animation: bg 5s infinite;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  color: var(--lightGreen);
  font-size: 3vw;
  font-weight: bold;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 1rem;
  border: 2px solid var(--lightGreen);
  border-radius: 0.5rem;
  animation: border 5s infinite;
  transition: 2s ease-in-out;
  width: min(450px, 90%);
  font-family: MadHacker;
}
.button:hover {
  background-color: var(--lightGreen);
  color: var(--black);
  animation-name: beat;
  animation-timing-function: ease-in-out;
}
.header {
  font-family: MadHacker;
  color: var(--lightGreen);
  font-size: min(6rem, 12vw);
  margin: 0;
  text-align: center;
  animation: blink 3s ease-in-out infinite;
  width: min(800px, 90%);
}

@keyframes blink {
  20% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes border {
  0% {
    border: 2px var(--lightGreen) solid;
  }
  25% {
    border: 2px var(--darkGreen) solid;
  }
  50% {
    border: 2px var(--lightGreen) solid;
  }
  75% {
    border: 2px var(--darkGreen) solid;
  }
  100% {
    border: 2px var(--lightGreen) solid;
  }
}
@keyframes bg {
  0% {
    background-color: var(--lightGreen);
  }
  100% {
    background-color: var(--darkGreen);
  }
}

@keyframes beat {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
